.hero {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: url('./../../Assets/featurepic.jpg') no-repeat center center/cover ;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
}

.hero .content p:first-child {
    font-style: italic;
}

.hero .content p:nth-child(2) {
    font-size: 1.9rem;
    text-shadow: 1px 1px 1px var(--secondary-color);
    margin: 0;
}

.hero .content p:nth-child(3){
    font-size: 5rem;
    font-weight: 700;
    text-shadow: 1px 1px 4px var(--secondary-color);
}

.hero .content p:nth-child(4) {
    font-size: 3.4rem;
    font-style: italic;
    font-weight: 700;
}

.hero .content button {
    margin: 2rem 0;
    }

/* <<<<< Media Query >>>>> */

@media screen and (max-width:940px) {

    .nav-menu a {
        font-size: 25px;
    }
    .hero .content {
        /* padding-left: 16%; */
        align-items: center;
    }
    
    .hero .content p:nth-child(2) {
        font-size: 1.2rem;
    }
    
    .hero .content p:nth-child(3) {
        font-size: 2.1rem;
    }

    .hero .content p:nth-child(4) {
        font-size: 1.6rem;
    }
}