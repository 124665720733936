.navbar {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.993);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
}

.navbar .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.navbar .nav .logo img {
  width: 60px;
  height: auto;
  border-radius: 50%;
}

.nav-menu {
  display: flex;
}

.nav-menu a {
    color: var(--secondary-color);
    text-shadow: 1px 1px 1.5px black;
    font-size: 30px;
}

.nav-item {
  padding: 0 2rem;
  transition: 0.8s ease;
}

.nav-item .active {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
  cursor: pointer;
}

.hamburger {
  display: none;
}

/* <<<<< Media Query >>>>>> */

@media screen and (max-width: 700px) {
  .navbar {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .nav {
    max-width: 100%;
  }

  .hamburger {
    display: block;
    color: #ffde59;
    font-size: 30px;
  }

  .nav-menu {
    position: fixed;
    right: -100%;
    top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 90vh;
    z-index: 10;
    transition: 0.4s ease-in;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-item {
    margin: 3.5rem 0;
  }

  .navbar .nav .logo img {
    width: 60px;
  }
}
