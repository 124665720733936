.testimonials {
    width: 100%;
    height: 700px;
    background-color: var(--overlay);
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

.testimonials:before {
    content: '';
    background: url('./../../Assets/featurepic2.jpg') no-repeat center center/cover;
    background-attachment: fixed; /* This makes the background image remain static */
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.cont {
    width: 80%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.cont h2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    gap: 5rem;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    max-width: 30%;
    min-width: 30%;
    border: 3px solid var(--primary-color);
    padding: 1rem;
    border-radius: 2rem;
    /* Glassy background */
    background: rgba(10, 1, 1, 0.267); /* Semi-transparent background */
    backdrop-filter: blur(15px); /* Blurring effect */
    -webkit-backdrop-filter: blur(40px); /* For Safari */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.card > img {
    width: 6rem;
    border-radius: 0% 50% 30% 50%;
}

.card > :nth-child(2) {
    font-style: italic;
    line-height: 125%;
    text-shadow: 1px 1px 1px solid black;
}

.card > :nth-child(3) {
    color: var(--primary-color);
    font-size: 24px;
    margin: -15px 0;
    text-shadow: 5px 1px 1px solid black;
}

.card > :nth-child(4) {
    color: var(--primary-color);
    font-size: 24px;
    margin: -15px 0;
    text-shadow: 5px 1px 1px solid black;
}

/* <<<<< Media Query >>>>> */

@media screen and (max-width:940px) {
    .testimonials {
        height: max-content;
        padding: 2rem 0;
    }

    .cont {
        width: 100%;
    }
    
    .cont span {
        width: 40%;
    }

    .content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card {
        max-width: 90%;
        min-width: 90%;
        height: max-content;
    }
    
}


