* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --primary-color: #eebf03;
  --primary-dark: #a0820a;
  --secondary-color: #e3d002;
  --overlay: rgba(0, 0, 1, 0.096);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  font-family: 'Times New Roman', Times, serif;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


li {
  list-style-type: none;
}

a {
  text-decoration: none;
  font-size: 25px;
}

button {
  padding: 15px 25px;
  font-size: 1rem;
  font-family: 'Times New Roman', Times, serif;
  color: whitesmoke;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-dark);
  border: 3px solid var(--primary-color);
  border-radius: 1rem;
  cursor: pointer;
  transition: .3s ease-in;
}

button:hover {
  background: var(--primary-dark);
  border: 3px solid rgb(238, 238, 214);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}


/* <<<<< Media Query >>>>> */

@media screen and (max-width:940px) {
  a {
    text-decoration: none;
    font-size: 23px;
  }
}