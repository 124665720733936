.footer {
  width: 100%;
  height: 100px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 80%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.sect {
  width: 410px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.hd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.socials {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  color: white;
}

.email button {
  padding: 10px 10px;
  margin-top: -3px;
  text-transform: none;
}

/* <<<<< Media Query >>>>> */

@media screen and (max-width: 940px) {
    .footer {
        height: 250px;
    }
    .container {
        width: 100%;
        flex-direction: column;
    }
}
