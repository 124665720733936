.about {
    background-color: rgba(224, 208, 208, 0.466);
    display: flex;
    padding: 0rem 1rem;
    width: 100%;
    height: 750px;
}

.container {
    width: 1240px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-1 {
    width: 50%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-1 img {
    width: 30rem;
    border-radius: 25%;
    border: 8px solid var(--primary-color);
}

.col-2 {
    width: 50%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.col-2 h1 {
    font-size: 3rem;
}

.col-2 h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.col-2 h4 {
    font-size: 1.4rem;
}



.about .col-2 p:first-of-type {
    margin: 2rem 0;
    text-align: justify;
    color: rgba(0, 0, 0, 0.747);
}

.about .col-2 p:nth-child(6) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    color: rgba(0, 0, 0, 0.712);
}

button {
    margin-top: 1.5rem;
}

/* <<<<< Media Query >>>>> */

@media screen and (max-width:940px) {

    .about {
        display: flex;
        height: max-content;
    }

    .container {
        flex-direction: column;
        margin: 4rem 0;
    }

    .col-1 {
        width: 100%;
    }

    .col-1 img {
        width: 20rem;
    }

    .col-2 {
        width: 100%;
        padding: 3rem 0;
    }

    .col-2 h2 {
        font-size: 1.8rem;
        margin: 1rem 0;
    }

    .col-2 h4 {
        text-align: center;
    }

    .about .col-2 p:first-of-type {
        margin: 2rem 0;
        padding: 0 1rem;
        text-align: left;
        line-height: 150%;
    }

    .about .col-2 p:nth-child(6) {
        padding: 0 1rem;
        line-height: 2.5rem;
    }
}