.portfolio {
    width: 100%;
    height: 100vh;
    /* background-color: rgba(128, 128, 128, 0.253); */
    color: var(--primary-dark);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: 1rem;
}

.portfolio:before {
    content: '';
    background: url('./../../Assets/upwork.png') no-repeat center center/cover;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
}

.embla-container {
    min-width: 70%;
    max-width: 70%;
    height: 60%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 2rem;
    padding-left: 1.5rem;
    /* background-color: red; */
}

.scrollBtn {
    width: 90%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.scrollBtn button {
    font-size: 35px;
    padding: 5px 15px;
    border-radius: 50%;
    font-weight: 900;
    background-color: rgba(0, 0, 0, 0.726);
    cursor: pointer;
}

.video {
    max-width: 90%;
    min-width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 2px var(--primary-color), -1px -1px 2px var(--primary-color);
    border-radius: 1rem;
     /* Glassy background */
     background: rgba(10, 1, 1, 0.267); /* Semi-transparent background */
     backdrop-filter: blur(25px); /* Blurring effect */
     -webkit-backdrop-filter: blur(60px); /* For Safari */
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.lside {
    width: 35%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(37, 28, 28);
    text-shadow: 2px 2px 2px var(--primary-color);
    font-size: 35px;
    gap: 4rem;
}

.lside :nth-child(2){
    padding: 0 2rem;
    text-align: center;
}

.rside {
    width: 65%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}








/* <<<< Media Query >>>> */

@media screen and (max-width:940px) {
    .portfolio {
        gap: 0rem;
        height: 100vh;
    }
    
    .portfolio:before {
        content: '';
        background: url('./../../Assets/upwork\ 2.png') no-repeat top left/cover;
        background-attachment: fixed;
        
    }

    .embla-container {
        max-width: 98%;
        min-width: 98%;
        height: 80%;
    }

    .video {
        flex-direction: column;
        width: 95%;
        height: 70%;
    }

    .lside {
        width: 90%;
        gap: 0rem;
        height: 80px;
    }

    .lside :nth-child(1) {
        font-size: 30px;
        margin-bottom: 1rem;
    }

    .lside :nth-child(2) {
        padding: 0;
        font-size: 18px;
    }

    .rside {
        width: 90%;
    }

    .rside iframe {
        height: 250px;
    }
}